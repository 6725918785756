import React from "react";
import { Link } from "react-router-dom";
import Logo480 from "../../assets/ONCA_480.svg";

const Header = () => {
  return (
    <header>
      <Logo480 />
      {/* <nav>
        <ul>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contacts">Contacts</Link></li>
          <li className="bottom"><Link to="/services">Services</Link></li>
          <li className="bottom"><Link to="/portfolio">Portfolio</Link></li>
        </ul>
      </nav> */}
      <h2>Onca Development</h2>
    </header>
  )
}

export default Header;