import React from "react";
import { Link } from "react-router-dom";

import IconPhone from "../../assets/material-call.svg"
import IconMail from "../../assets/material-mail.svg"
import IconLinkedIn from "../../assets/linkedin-brands-solid.svg"

const Contact = () => {

  const handleEmail = () => {
    window.open("mailto:contact@oncadevelopment.com?subject=Let\'s get in touch", "_blank")
  }

  return <>
    <main id="contact-page">
      <section>
        <h1>Contact</h1>
        <ul>
          <li>
            <IconMail />
            <a href="mailto:contact@oncadevelopment.com?subject=Let\'s get in touch" target="_blank">contact@oncadevelopment.com</a>
          </li>
          <li>
            <IconPhone />
            <a href="tel:+16464944748" target="_blank">+1 (646) 494-4748</a>
          </li>
          <li>
            <IconLinkedIn />
            <a href="https://www.linkedin.com/company/onca-development" target="_blank">/onca-development</a>
          </li>
        </ul>

      </section>
      <section>
        <h1>Mailing address</h1>
        <p>2248 Broadway, #1901</p>
        <p>New York, NY 10024</p>

      </section>
    </main>
    <footer>
      <Link to="..">&lt; Back</Link>
      <button onClick={handleEmail}>Send us an email</button>
    </footer>
  </>
}

export default Contact;