import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  return <>
    <main>
      <h1>ONCA is a real asset development agency based in New York, Istanbul, and Paris.</h1>
      <p>Our network of expert designers, engineers, analysts and strategists provide custom, high-tech solutions that enhance the value, efficiency, and sustainability of your assets throughout their lifecycle.</p>
      {/* <section className="card">
        <h2>Styling</h2>
        <p>Text explaining styling.</p>
      </section> */}
    </main>
    <footer>
      <Link to="/contact">
        <button>Have a project? Let's talk 👉</button>
      </Link>
    </footer>
  </>
}

export default Home;