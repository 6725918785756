import React from "react";

const Footer = (props) => {

  return <footer>
    <button>Have a project? Let's talk 👉</button>
  </footer>
}

Footer.defaultProps = {
  
};

export default Footer