import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import pages
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

const App = () => {
  return <>
    <React.StrictMode>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="*" element={<Home />}/>
          <Route path="/contact" element={<Contact />}/>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </>
}

export default App;